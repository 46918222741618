@import '../../variables/fonts.scss';
@import "../../variables/variables.scss";
@import "./projectElement.scss";

@media (min-width: 768px) {

.main-wrapper-portefolioList{
  background-color: $background-color;
  display: flex;
  justify-content: center;
}

.div-container-img-h-p {
  @include grid-lg;
  display: grid;
  align-items: center;
  width: 100%;
  height: 600px;
  max-width: $max-width;
}

.div-portefolio-img{
  grid-column: 3/ span 3;
  width: 100%;
}

.portefolio-img{
  width: 100%;
}

.div-h-p-portefolioList {
  grid-column: 6/ span 5;
  width: 100%;
  padding-left: 53px;
}
.main-div-portefolioList-project{
   max-width: $max-width;  
   margin-right: auto;
  margin-left:auto;
}
.main-container-portefoliolist-project{
  @include grid-lg;
  display: grid;
  margin-top: 64px;

}

.a-project-element{
  grid-column: span 4;
  width: 100%;
}

.portefolio-title-portefolioList {
  font-size: $font-size-title-h1-desktop;
  margin-bottom: 16px;
}

.paragraph-portefoliolist-first-part {
  font-family: $font-paragraph;
}
}
//média mobile

@media (max-width: 500px) {
  .main-container-portefolioList {
    max-width: $max-width;
  }
  
  .div-container-img-h-p {
    @include grid-lg;
    display: grid;
    width: 100%;
    height: 450px;
    background-color: $background-color;
    margin: 0;
    margin-bottom: 40px;
  }
  
  .div-portefolio-img{
    grid-column: $full-grid;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  
  .portefolio-img{
    width: 100%;
  }
  
  .div-h-p-portefolioList {
    grid-column: $full-grid;
    padding: 0px;
    width: 100%;
    text-align: center;
  }
  
  .main-container-portefoliolist-project{
    @include grid-lg;
    display: grid;
    max-width: $max-width;
  }
  
  .a-project-element{
    grid-column: $full-grid;
    width: 100%;
  }
  
  .portefolio-title-portefolioList {
    font-size: $font-size-title-h1-mobile;
    margin-bottom: 16px;
  }
  
  .paragraph-portefoliolist-first-part {
    font-family: $font-paragraph;
  }
  
}

//média tablette 

@media (min-width: 501px) and (max-width: 767px) {
  .main-container-portefolioList {
    max-width: $max-width;
  }
  
  .div-container-img-h-p {
    @include grid-lg;
    display: grid;
    width: 100%;
    height: 450px;
    background-color: $background-color;
    margin: 0;
    margin-bottom: 40px;
  }
  
  .div-portefolio-img{
    grid-column: $full-grid;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
  }

  
  .portefolio-img{
    width: 100%;
  }
  
  .div-h-p-portefolioList {
    grid-column: $full-grid;
    padding: 0px;
    width: 100%;
    text-align: center;
  }
  
  .main-container-portefoliolist-project{
    @include grid-lg;
    display: grid;
    max-width: $max-width;
    padding-top: 40px;
  }
  
  .a-project-element{
    grid-column: span 6;
    width: 100%;
  }
  
  .portefolio-title-portefolioList {
    font-size: $font-size-title-h1-mobile;
    margin-bottom: 16px;
  }
  
  .paragraph-portefoliolist-first-part {
    font-family: $font-paragraph;
  }
  
}

