@import '../../variables/fonts.scss';
@import "../../variables/variables.scss";

header {
  position: sticky;
  height: 64px;
  top: 0;
  z-index: 2;
  width: 100%;
  background-color: $background-color-menu;
}
.principal-div-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 25px;
  height: 64px;
  .inferior-div-header {
    display: flex;
    justify-content: flex-end;
  }
  .menu{
    margin-left: 48px;
    transition: 0.3s;
    &:hover {
      color: $font-menu-color-hover;
      transition: 0.3s;
    }
  }
}
li {
  list-style: none;
}
a {
  @extend %title-bold;
  text-decoration: none;
  font-size: $font-size-menu-desktop;
}

.active-link {
  color: $font-menu-color-hover;
}

@media (max-width: 767px) {
  .menu-pc {
    display: none;
  }
  .principal-div-header {
    margin: 0px 25px 16px 32px;
  }
}

@media (min-width: 768px) {
  .button-burger-mobile {
    display: none;
  }
}
