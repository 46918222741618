@import '../../variables/fonts.scss';
@import "../../variables/variables.scss";

.main-div-project{
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}
.container-project{
  @include grid-lg;
  display: grid;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 64px;
}

.container-box{
  margin-left: 40px;
  grid-column: 1/span 5;

}

.container-picture-box{
  height: 320px;
  width: 100%;
  
}
.picture-project{
  height: 320px;
  width:100%;
}

.title-project-text{
  margin-left: 40px;
  grid-column: 6/ span 6;
  width: 100%;
}


.title-project-text {
  margin-right: 135px;
  margin-left: 40px;
  h1 {
    @extend %title-bold;
  }
}

.paraph-project-one{
  margin: 0px;
}
.button-see-more {
  @extend %button-base, 
  %button-three;
  margin-top: 0px;
}

.container-button-see-more {
  display: flex;
  justify-content: flex-end;
}

.container-box-two{
  grid-column: 8/ span 5;
  margin-right: 40px;
}
.title-project-text-two {
grid-column: 2 / span 6;
  h1 {
    @extend %title-bold;
  }
}



// média mobile 

@media (max-width: 500px){

  
.container-project{
  @include grid-lg;
  display: grid;
}

.container-project-two {
  display: flex;
  flex-direction: column-reverse;
  align-items: baseline;
}
.container-box{
  grid-column: $full-grid;
  margin: 48px 0px 24px 0px;

}

.container-picture-box{
  height: 220px;
}
 
.title-project-text,
.title-project-text-two{
  grid-column: $full-grid;
  width: 100%;
  margin: 0px;
}

.title-project-text-two{
  margin-top: 20px;
}

.title-class{
  margin: 0px;
  font-size: 26px;
  padding: 0px 16px;
}

.paraph-project{
  padding: 0px 16px;
  text-align: justify;
}

.button-see-more { 
  margin: 24px 0px;
  grid-column: $full-grid;
}

.container-button-see-more{
  justify-content: center;
}
.container-box-two{
grid-column: $full-grid;
width: 100%;
padding-top: 20px;
}

}