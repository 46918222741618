@import '../../variables/fonts.scss';
@import "../../variables/variables.scss";

.container-principal-presentation-profil {
  max-width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  background-color: $background-color;
}

//grid 
.secondary-container-presentation-profil {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  @include grid-lg;
  display: grid;
  align-items: center;
}

.picture-bodart-div{
  grid-column: 3/ span 3;
}
.picture-bodart{
  width: 100%;
}

.container-text-presentation-profil {
  margin-left: 30px;
  grid-column: 6/ span 5;
}
//

.h1-title-presentation-profil {
  @extend %title-bold;
  font-size: $font-size-title-h1-desktop;
  margin: 4px 0px;
}

.h2-presentation{
  margin: 8px 0px;
}
.p-presentation{
  margin-bottom: 40px;
}


//buttons
.projects-button-presentation {
  @extend %button-one;
}

.resume-button-presentation {
  @extend %button-two;
  margin-left: 24px;
  min-width: 100px;
  padding: 8px 45px;
}

.button-presentation {
  @extend %button-base;
  margin-top: 40px;
}
//

// média mobile

@media (max-width: 500px){
  
.container-principal-presentation-profil {
  max-width: 100%;
  height: 538px;
  display: flex;
  background-color: $background-color;
}

//grid 
.secondary-container-presentation-profil {
  max-width: $max-width;
  @include grid-lg;
  display: grid;
  align-items: center;
  height: 450px;
}

.picture-bodart-div{
  grid-column: 1/ span 12;
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
}


.container-text-presentation-profil {
  grid-column: $full-grid;
  margin: 0px;
  text-align: center;
}
//


.h1-title-presentation-profil {
  font-size: 32px;
  margin: 8px 0px;
}

.h2-presentation{
  margin: 8px 0px;
  font-family: $font-light;
}

//buttons
.projects-button-presentation {
  color: #ffffff;
  background-color: #356cff;
  border: solid 2px #356cff;
  &:hover {
    background-color: #ffffff;
    color: #356cff;
  }
}

.resume-button-presentation {
  margin-left: 24px;
}

.button-presentation {
  margin-top: 40px;
}
}


