@import '../../variables/fonts.scss';
@import "../../variables/variables.scss";


.img-portefolio {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  height: 280px;
}
.container-project-portefolio {
  margin-bottom: 32px;
  box-shadow: 0 4px 8px 2px rgba(53, 108, 255, 0.05);
  border-radius: 16px;
  transition: all 0.4s;
  &:hover {
    transform: scale(1.05);
    transition: all 0.4s;
  }
}

.container-project-details-porteflio {
  padding: 24px;
}

.title-project-portefoliolist {
  font-size: 24px;
  margin: 0px 0px 8px 0px;
}

.name-company {
  font-family: $font-paragraph;
  color: $color-font-company;
  font-size: $font-size-paragraph-desktop;
}

.name-category {
  font-family: $font-paragraph;
  color: #2a2a2a;
  font-size: $font-size-paragraph-desktop;
}

@media (max-width: 500px) {

.img-portefolio{
  height: 220px;
}
}