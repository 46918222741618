@import '../../../variables/variables.scss';
@import '../../../variables/fonts.scss';

.div-project-page{
    @include grid-lg;
    display: grid;
    margin-bottom: 100px;
}

//main picture project
.main_picture_background{ 
    grid-column: 2/ span 10;
    width: 100%;
}

//component's contruction with grids
.main_div_project_element{
    grid-column: 3/ span 8;
}

// explanatony pictures 
.explanatory_img{
    width: 100%;
    margin: 24px 0px 8px 0px;
}

//titles
.title-project-component{
    font-family: $font-bold;
    font-size: $font-size-title-h1-desktop;
    margin: 32px 0px 24px 0px;
}

//subtitles
.subtitle-project-component{
    font-family: $font-bold;
    font-size: $font-size-title-h2-desktop;
    margin: 32px 0px 24px 0px;
}

//paragraphs + legends 
.paragraph-project-component{
    font-family: $font-paragraph;
    font-size: $font-size-paragraph-desktop;
    text-align: justify;
    margin: 10px 0px;
}

.legend-project-component{
    font-family: $font-paragraph;
    font-style: italic;
    text-align: center;
    margin: 0px;
}

.link-component{
    font-family: $font-paragraph;
    font-size: $font-size-paragraph-desktop;
}

Footer{
    grid-column: $full-grid;
}



//média mobile

@media (max-width: 500px) {
    
.div-project-page {
margin: 0;
}

//main picture project
.main_picture_background{ 
    grid-column: $full-grid;
    width: 100%;
}

//component's contruction with grids
.main_div_project_element{
    grid-column: 2/ span 10;
}

// explanatony pictures 
.explanatory_img{
    width: 100%;
    margin: 24px 0px 8px 0px;
}

//titles
.title-project-component{
    font-family: $font-bold;
    font-size: $font-size-title-h1-mobile;
    margin: 32px 0px 24px 0px;
}

//subtitles
.subtitle-project-component{
    font-family: $font-bold;
    font-size: $font-size-title-h2-mobile;
    margin: 32px 0px 24px 0px;
}
    }

.link-component{
    color: $font-color-base;
}