@import '../../variables/fonts.scss';
@import "../../variables/variables.scss";


// grids first part AboutPage
.background-div-about{
    background-color: $background-color;
}

.container-about-hi {
    @include grid-lg;
    display: grid;
    height: 600px;
    align-items: center;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
}

.bodartpng {
    grid-column: 3/ span 3;
    width: 100%;
}

.div-presentation-about{
    grid-column: 6/ span 5;
}

// css 
.name-h1-about{
    font-size: $font-size-title-h1-desktop;
}

.presentation-about{
    font-family: $font-paragraph;
    font-size: $font-size-paragraph-desktop;
}


// grids & css second part 

.container-about-hobbies{
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
}
.p-about-second-presentation-div{
    @include grid-lg;
    display: grid;
}

.paragraph-second-presentation-about{
    grid-column: 3/span 8;
    width: 100%;
    text-align: center;
    font-size: 18px;
    margin: 64px 0px;
}

.div-hobbies{
    @include grid-lg;
    display: grid;
    margin-left: 40px;
    margin-bottom: 80px;
    align-items: center;
}

.hobbies-img{
   grid-column: 1/span 5;
   width: 100%;
}


.h1-paraph-hobbies{
    grid-column: 6/span 6;
    margin-left: 20px;
    h1 {
        font-size: 32px;
    }
    p{
        font-family: $font-paragraph;
        font-size: 20px;
    }
}

.hobbies-list {
    font-size: 20px;
    font-family: $font-paragraph;
    list-style-type: disc;
}

.hobbies-ul{
    padding-left: 20px;
}


// mobile & tablette 

@media (max-width: 500px) {


    .background-div-about{
        background-color: #f8faff;
        height: 450px;
    }
    .container-about-hi {
        @include grid-lg;
        display: grid;
        align-items: center;
        height: inherit;
    }
    
    .bodartpng {
        grid-column: $full-grid;
        width: 55%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
    
    .div-presentation-about{
        grid-column: $full-grid;
        width: 100%;
        text-align: center;
        margin: 0;
    }
    
    .name-h1-about{
        font-size: $font-size-title-h1-mobile;
    }
    
    .presentation-about{
        font-family: $font-paragraph;
        font-size: $font-size-paragraph-mobile;
    }
    
    
    .p-about-second-presentation-div{
        @include grid-lg;
        display: grid;
    }

    .paragraph-second-presentation-about{
        grid-column: $full-grid;
        width: 100%;
        text-align: center;
        margin-bottom: 64px;
    }

    .div-hobbies{
        @include grid-lg;
        display: grid;
        margin-bottom: 40px;
        align-items: center;
    }

    .hobbies-img{
       grid-column: $full-grid;
    }
    
    .h1-paraph-hobbies{
        grid-column: $full-grid;
        margin: 0px;
        h1 {
            font-size: 20px;
            text-align: center;
        }
        p{
            font-family: $font-paragraph;
            font-size: $font-size-paragraph-mobile;
            text-align: center;
        }
    }
    
    .hobbies-list {
        font-size: $font-size-paragraph-mobile;
        font-family: $font-paragraph;
        list-style-type: disc;
    }
}