@import '../../variables/fonts.scss';
@import "../../variables/variables.scss";

h2 {
  font-family: $font-regular;
}

input {
  margin-top: 40px;
  font-family: $font-bold;
  @extend %button-base;
  font-size: 18px;
  outline: none;
}

h1 {
  font-family: $font-bold;
}
