@import '../../variables/fonts.scss';
@import "../../variables/variables.scss";
//grid

.div-secondary-container-contact{
    @include grid-lg;
    display: grid;
    grid-column: $full-grid;
}
.main-container-h1-text-contact{
    grid-column: 3/ span 8;
    width: 100%;
}
.formulaire-contact-div{
    grid-column: 3/ span 8;
}

form {
    @include grid-lg;
    display: grid;
}

.main-container-form{
    grid-column: $full-grid;
    display: flex;
}

.second-container-form{
    grid-column: $full-grid;
}
.div-button-send{
    grid-column: $full-grid;
    display: flex;
    flex-direction: row-reverse;
}

// css

.div-email,
.div-subject-title{
    width: 50%;
}

.div-email{
    margin-right: 20px;
}
.main-container-contact{
    background-color: $background-color;
    height: 100vh;
}

.main-title-contact {
    font-size: $font-size-title-h1-desktop;
}

.name-label {
    font-family: $font-bold;
    font-size: 18px;
    margin-bottom: 8px;
}
.paraph-contact {
    font-family: $font-paragraph;
    font-size: $font-size-paragraph-desktop;
    color: #2a2a2a;
    margin-top: 16px;
    margin-bottom: 48px;
}


.object-input {
    box-sizing: border-box;
    width: 100%;
    height: 120px;
    background-color: #f8faff;
    border: solid 1px #9aa3bc;
    border-radius: 8px;
    font-family: $font-paragraph;
    outline: none;
}

.subject-input,
.email-input {
    margin: 0px;
    background-color: #f8faff;
    border: solid 1px #9aa3bc;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
}
.email-input{
    margin-right: 16px;
}
.div-object-text{
    margin-top: 16px;
}

.button-send{
    margin-top: 32px;
    @extend %button-base;
    font-family: $font-bold;
    font-size: 18px;
    color: #ffffff;
    background-color: #356cff;
    border: solid 2px #356cff;
    border-radius: 8px;
}

.form-message,
.form-message-succes{
    grid-column: 1/ span 12;
    display: flex;
    flex-direction: row-reverse;
    color: $font-color-base;
    font-family: $font-regular;
    margin-top: 20px;
}



// scss mobile 

@media (max-width: 500px) {
    //grid

    .div-secondary-container-contact{
        @include grid-lg;
        display: grid;
        grid-column: $full-grid;
    }
    .main-container-h1-text-contact{
        grid-column: 2/ span 10;
        width: 100%;
        text-align: center;
    }
    .formulaire-contact-div{
        grid-column: $full-grid;
    }
    
    form {
        display: block;
    }
    
    .second-container-form{
        grid-column: $full-grid;
    }
    .div-button-send{
        grid-column: $full-grid;
        display: flex;
        flex-direction: row-reverse;
    }

    .button-send{
        width: 100%;
    }
}