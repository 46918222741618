@import "../../variables/variables.scss";

.backdrop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $background-color-backdrop;
  z-index: 100;
  cursor: pointer;
}
