@import '../../variables/fonts.scss';
@import "../../variables/variables.scss";
footer {
  min-width: 100%;
  height: 34px;
}

.footer-base {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-made {
  font-family: $font-regular;
  font-size: $font-size-footer-desktop;
  padding-left: 32px;
  }

  .social-network{
    margin-right: 32px;
  }

.social-network-logo {
  width: 18px;
  height: 18px;
  margin: 8px 0px;
  background-color: $background-color;
  &:hover {
    cursor: pointer;
    fill: #ffffff;
  }
}

.linkedin-logo{
  margin-left: 25px;
}

@media (max-width: 500px){

  footer {
    min-width: 100%;
  }
  
  .footer-base {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .p-made {
    font-family: $font-light;
    font-size: $font-size-footer-mobile;
  }
  
  .social-network-logo {
    width: 9px;
    height: 9px;
    margin: 8px;
    padding: 2px;
    background-color: $background-color;
    &:hover {
      cursor: pointer;
    }
  }
  

}