@import '../../variables/fonts.scss';
@import "../../variables/variables.scss";

.container-skills {
  margin: 0;
  background-color: $background-color;
  width: 100%;
  display: flex;
  justify-content: center;
  h1 {
    font-size: $font-size-title-h1-desktop;
    font-family: $font-bold;
  }
}

.container-differents-skills{
  max-width: $max-width;
}

//grid dimensions 
.container-skills-top,
.container-skills-bottom{
  display: grid;
  @include grid-lg;
 }

 .container-skills-top-left,
 .container-skills-bottom-left{
   display: grid;
   grid-column: 2/ span 5;
   display: flex;
 }
 
 .container-skills-top-right,
 .container-skills-bottom-right{
   display: grid;
   grid-column: 7/ span 5;
   display: flex;
 }
// 
 
.skills-h1-title {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
}

.img-skills {
  margin-right: 16px;
  min-width: 88px;
  height: 88px;
  background-color: #ffffff;
  border: 0ch;
  border-radius: 4px;
}
.container-skills-img {
  display: flex;
  align-items: center;
  margin-right: 16px;
}


.div-skills-details {
  max-width: 80%;
  h2 {
    font-family: $font-bold;
    font-size: $font-size-title-h2-desktop;
  }
}

.div-button-skills-resume {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 80px;
}
.input-skills-resume {
  @extend %button-two;
}


// média mobile 

@media (max-width: 500px){

 .container-skills-top-left,
 .container-skills-top-right,
 .container-skills-bottom-left,
 .container-skills-bottom-right{
   grid-column: $full-grid;
   width: 100%;
 }
.container-skills-img {
  align-items: center;
  margin-right: 8px;
}
 
.div-skills-details {
  h2 {
    font-family: $font-bold;
    font-size: 32px;
  }
  p{
    padding-right: 16px;
    text-align: justify;
  }
}
.skills-h1-title {
  grid-column: $full-grid;
  margin: 0px;
  text-align: center;
}

.img-skills {
  margin-right: 16px;
  min-width: 88px;
  height: 88px;
  background-color: #ffffff;
  border: 0ch;
  border-radius: 4px;
}

.div-button-skills-resume {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 80px;
}

}